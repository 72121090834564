import path from "path";

// filepath: /C:/Users/james/Desktop/site/ts-react-jarrardxyz/app/src/Blogs/blogList.ts
const blogList = [
  {
    title: "Home Server LLM Discord Bots",
    path: "/blog/02-02-25",
    component: require("./02-02-25").default,
  },
  {
    title: "New year's resolution - Charity",
    path: "/blog/26-01-25",
    component: require("./26-01-25").default,
  },
  {
    title: "The beginning of something new",
    path: "/blog/18-01-25",
    component: require("./18-01-25").default,
  },

  // Add more blog entries here
];

export default blogList;
