// filepath: /C:/Users/james/Desktop/site/ts-react-jarrardxyz/app/src/Blogs/17-01-25.tsx
import React from "react";
import { Box, Heading, Link, Text, Image, Spacer } from "@chakra-ui/react";
import Header from "../Components/Header";
import SpeakHelpImg from "../Images/speakhelp.png";

const Blog020225: React.FC = () => {
  return (
    <>
      <Header />

      <Box p={4} maxW="800px" mx="auto">
        <Heading as="h1" size="2xl" mb={4} textAlign="center">
          Home Server LLM Discord Bots
        </Heading>
        <Text fontSize="lg" mb={10} textAlign="center">
          2nd Febuary, 2025
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          Another week gone, went by faster than Deepseek R1 says Hi on a 2 core
          home server.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          This week wasn’t robots, wasn’t speech tools, it was LLM Discord bots,
          and what I could squeeze onto my Lenovo Tiny M700 home server. Specs:
          Ubuntu Server in ESXi, 2 cores, 10GB RAM, 50GB SSD.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          Turns out, not much without a GPU (I knew this before I started). The
          answer was anything 3b or below, 4b started to suffer.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          Deepseek-r1:1.5b - great if you can prompt it without confusing it. 7b
          lacked resources but did a much better job.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          Llama3.2:3b - great as long as you ask it to do basic stuff, code,
          maths, history. But outside that scope, guardrails, GUARDRAILS
          EVERYWHERE! “I am but an assistant I cannot pretend, theorise, guess,
          or answer hypothetical questions".
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          All of these small models struggle with reading conversation history,
          where the larger models don’t struggle at all.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          So yeah, week 3 I context switched again, what’s next week? Who knows.
        </Text>
        <Link href="https://ollama.com/models" color="blue.300" isExternal>
          Ollama Models
        </Link>
        <Spacer h={10} />
        <Link
          href="https://discord.com/developers/applications/"
          color="blue.300"
          isExternal
        >
          Discord Developer Portal
        </Link>
        <Spacer h={10} />
      </Box>
    </>
  );
};

export default Blog020225;
