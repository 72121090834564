// filepath: /C:/Users/james/Desktop/site/ts-react-jarrardxyz/app/src/Blogs/17-01-25.tsx
import React from "react";
import { Box, Heading, Link, Text, Image, Spacer } from "@chakra-ui/react";
import Header from "../Components/Header";
import roboArm from "../Images/robotarm.jpg";
const Blog180125: React.FC = () => {
  return (
    <>
      <Header />

      <Box p={4} maxW="800px" mx="auto">
        <Heading as="h1" size="2xl" mb={4} textAlign="center">
          The beginning of something new
        </Heading>
        <Text fontSize="lg" mb={10} textAlign="center">
          18th January, 2025
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          Welcome to the first entry of my blog! This marks the beginning of the
          en- I mean, start of something new.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          I've always wanted to write a blog, but never thought what I was doing
          was interesting enough. In my daily life I'm a SWE working with
          cutting edge AI, LLMs, image generation, VR, AR, and all sorts of new
          tech. Outside of work I have a passion for robotics, and have been
          building kits and dabbling with microcontrollers for years.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          All this time I've been jealous of the people that studied robotics,
          people who develop robots in shiny white lab coats in Silicon Valley,
          Imagineers at Disney creating incredible animatronics etc. Fortunately
          for me technology in the past few years has become about being open
          and accessible to those that are interested. Kind people on GitHub and
          YouTube sharing their hobby projects, writing drivers for cheap cheap
          electronic sensors and motors, unlocking finacky distance sensors and
          vision systems to the masses.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          So in that spirit I'm going to start writing about my out of work
          projects. I'm building a bipedal robot using 3D printing and the most
          accessible parts I can acquire, mainly from old 3D printers, CNC motor
          drivers, V slot tubing, and a few bolts.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          I'll try update this blog with my progress once a week, but if I don't
          it might be on my Instagram.
        </Text>
        <Link
          href="https://www.instagram.com/jarrardxyz/"
          color="blue.300"
          isExternal
        >
          JarrardXyz
        </Link>
        <Spacer h={10} />
        <Image width={"100%"} src={roboArm} alt="3D Printed Robotic Arm" />
      </Box>
    </>
  );
};

export default Blog180125;
