// filepath: /C:/Users/james/Desktop/site/ts-react-jarrardxyz/app/src/Blogs/17-01-25.tsx
import React from "react";
import { Box, Heading, Link, Text, Image, Spacer } from "@chakra-ui/react";
import Header from "../Components/Header";
import SpeakHelpImg from "../Images/speakhelp.png";

const Blog260125: React.FC = () => {
  return (
    <>
      <Header />

      <Box p={4} maxW="800px" mx="auto">
        <Heading as="h1" size="2xl" mb={4} textAlign="center">
          New year's resolution - Charity
        </Heading>
        <Text fontSize="lg" mb={10} textAlign="center">
          26th January, 2025
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          Welcome to the second entry of my blog! I came back but not with robot
          updates, sorry if that's disappointing.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          I've decided to start the new year with some charity. Keeping a long
          story short, my grandad had a stroke before I was born. I had never
          heard him speak more than the 3 words he could remember.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          I always wanted to create a tool to help him speak, unfortunately I
          never had the time, skills, or resources. So we come to now, a few
          years after his passing, I've finally gotten around to creating the
          tool I promised him.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          I've created a website that allows people with speech difficulties to
          communicate with common phrases, replies, and alerts. The website is
          called SpeakHelp.org and is completely free to use.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          I will never add adverts or charge for these tools. I just want to
          help in my own creative way.
        </Text>
        <Text fontSize="lg" mb={10} textAlign="justify">
          If you're a carer, a charity, or organisation that needs more from
          these tools, or even just a random passer by with some feedback, I
          urge you to contact me via the email in the top right so I can make
          the tools better for all.
        </Text>
        <Link href="https://speakhelp.org" color="blue.300" isExternal>
          SpeakHelp.Org
        </Link>
        <Spacer h={10} />
        <Image
          width={"100%"}
          src={SpeakHelpImg}
          alt="SpeakHelp.Org Screenshot"
        />
      </Box>
    </>
  );
};

export default Blog260125;
